<h2 class="text-secondary ms-5 mt-5"> Points </h2>

<app-app-table-filter
  [filters]="filters"
  [typeaheadData]="typeaheadData"
  [searchMode]="searchMode"
  [columns]="filtercolumns"
  [(filter)]="filter"
  [dataMap]="myMap"
  [objectType]="'MyMap'"
  (typeaheadSelect)="onTypeaheadSelect($event)"
  (updateQueryparams)="updateQueryParams()"
  (doFilter)="doFilter($event)"
></app-app-table-filter>

<div class="p-5 d-flex flex-column">
  <ngx-datatable
  #table
  class="table-element elevation-1"
  [rows]="myMap"
  [columns]="columns"
  [reorderable]="true"
  columnMode="force"
  [rowHeight]="tableConfig.rowHeightSmall"
  [limit]="10"
  [offset]="p - 1">
  </ngx-datatable>
</div>

<!--ID button to copy point id-->
<ng-template #copyIdPointButton let-value="value" let-row="row" ngx-datatable-cell-template>
  <app-short-id [id]="row.id"></app-short-id>
</ng-template>

<ng-template #copyPointGroupId let-value="value" let-row="row" ngx-datatable-cell-template>
  <button type="button" class="btn btn-tertiary me-6"
    [routerLink]="['/main/cov']"
    [queryParams]="{
    filterpc0: row.id,
    filterpcTitle0: 'ID'
    }"
    >cov</button>
</ng-template>

<ng-template #namespaceId let-value="value" let-row="row" ngx-datatable-cell-template>
  <app-short-id [id]="row.namespace"></app-short-id>
</ng-template>

<si-pagination [(currentPage)]="p" [totalPages]="numPages()" (currentPageChange)="adjustPage($event)">
</si-pagination>

<div class="container" *ngIf="getPointService.loading">
  <si-loading-spinner class="inline-spinner" data-cy="users-loading-spinner"></si-loading-spinner>
</div>

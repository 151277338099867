<div class="ps-5 pe-5">
    <si-filter-bar
      [responsive]="true"
      [filters]="filters"
      [allowReset]="false"
      (filtersChange)="removeFilter($event)"
    ></si-filter-bar>
  </div>

  <div class="row mt-4">
    <div class="col-3 col-lg-2">
      <button
        #trigger="cdkOverlayOrigin"
        type="button"
        class="btn btn-primary dropdown-toggle ms-5"
        cdkOverlayOrigin
        [class.show]="open"
        [attr.aria-expanded]="open"
        (click)="open = true"
      >
        {{ "Filter " + columns.get(searchMode) }}
        <i class="dropdown-caret icon element-down-2"></i>
      </button>

      <ng-template
        cdkConnectedOverlay
        cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
        [cdkConnectedOverlayOpen]="open"
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayHasBackdrop]="true"
        (backdropClick)="open = false"
        (detach)="open = false"
      >
        <div
          class="dropdown-menu position-static d-block"
          [cdkTrapFocus]="true"
          [cdkTrapFocusAutoCapture]="true"
        >
          <button
            *ngFor="let item of dropdownItems(columns)"
            class="dropdown-item"
            type="button"
            (click)="setFilter(item.value)"
          >
            {{ item.label }}
          </button>
        </div>
      </ng-template>
    </div>

    <div class="col-9 col-lg-10">
      <div class="pe-5">
        <si-search-bar
          placeholder="Search"
          aria-label="Search"
          [siTypeahead]="typeaheadData"
          [typeaheadSkipSortingMatches]="false"
          [typeaheadTokenize]="false"
          [disabled]="false"
          [showIcon]="true"
          [typeaheadScrollable]="true"
          [(ngModel)]="filter"
          (keydown)="onSearch($event)"
          (typeaheadOnSelect)="onTypeaheadSelect($event)"
          [typeaheadClearValueOnSelect]="true"
        ></si-search-bar>
      </div>
    </div>
  </div>

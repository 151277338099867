import { Component, inject, OnInit } from '@angular/core';
import { SiCookiesService } from '@building-x/common-ui-ng';
import { MenuItem, SiThemeService, ThemeType } from '@simpl/element-ng';

import { OrgService } from 'src/app/services/org.service';
import { GetDataService } from 'src/app/services/get-data.service';
import { GetPointsService } from 'src/app/services/get-points.service';
import { LocalisationService } from 'src/app/services/localisation.service';

@Component({
  standalone: false,
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  currentUser: any = { identity: { fullName: '', emailId: '' }, profile: {} };
  organizationsName?: string;
  organizationId?: string;
  navItem: MenuItem[];
  selectedValue: string;
  theme: ThemeType = 'auto';
  organizations: any[] = [];

  private themeService = inject(SiThemeService)

  constructor(
    private getData: GetDataService,
    private readonly cookieService: SiCookiesService,
    public organizationsService: OrgService,
    public getPointsService: GetPointsService,
    public translateService: LocalisationService
  ) {
    this.navItem = this.organizationsService.navItems;
    this.selectedValue = this.translateService.setLanguage();
    this.currentUser.attributes = { identity: { fullName: '', emailId: '' }, profile: {} };
  }

  menuItems: MenuItem[] = [
    {
      title: 'NAV.OVERVIEW',
      id: 'home',
      icon: 'element-home',
      tooltip: 'NAV.OVERVIEW',
      items: [{ title: 'HOME.HEADING', link: 'home', tooltip: 'HOME.HEADING' }]
    },
    {
      title: 'NAV.POINTGROUP',
      icon: 'element-group-lead',
      items: [
        { title: 'POINTGROUP.HEADING', link: 'point-group' },
        { title: 'POINTGROUP.MODIFY', link: 'point-group-modify' },
        { title: 'POINTGROUP.RELATION', link: 'point-group-point-relationship' }
      ]
    },
    {
      title: 'NAV.POINTS',
      icon: 'element-group-member',
      items: [
        { title: 'POINTS.HEADING', link: 'points' },
        { title: 'COVS', link: 'cov' }
      ]
    },
    {
      title: 'NAV.DEVICES',
      icon: 'element-workstation',
      items: [{ title: 'DEVICES.HEADING', link: 'devices' }]
    }
  ];

  helpItem: MenuItem = {
    title: 'Help',
    icon: 'element-help',
    items: [
      { title: 'NAV.ABOUT', link: '/main/about' },
      { title: '-' },
      { title: 'Corporate Information', href: 'http://www.siemens.com/corporate-information', target: '_blank' }
    ]
  };

  ngOnInit(): void {
    this.getUserInfo();
    this.organizationsService.getOrgInfo();
    this.organizationsName = this.cookieService.getCookie('organizationName');
    this.organizationId = this.cookieService.getCookie('organizationId');

    // Unternehmen abrufen - jetzt mit richtiger Methode!
    this.organizationsService.getOrganizations().subscribe((data: any) => {
      this.organizations = data.data;
    });
  }

  selectCompany(company: any) {
    this.organizationsName = company.attributes.name;
    this.organizationId = company.id;
    this.cookieService.setCookie('organizationName', this.organizationsName);
    this.cookieService.setCookie('organizationId', this.organizationId);
    window.location.reload();
  }

  getUserInfo() {
    this.getData.getUser().subscribe((Userdata: any) => {
      this.currentUser = Userdata.data;
    });
  }

  changeLanguage(language: string): void {
    this.translateService.setUserLanguage(language);
    this.selectedValue = language;
  }

  applyThemeType(theme: ThemeType): void {
    this.themeService.applyThemeType(theme);
  }
}

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { TableColumn } from '@siemens/ngx-datatable';

import { Filter } from '@simpl/element-ng';

import { GetPointsService } from 'src/app/services/get-points.service';
import { GetPointGroupsService } from 'src/app/services/get-point-groups.service';
import { MyMap, Point, PointValues } from 'src/app/model/point.models';
import { SI_DATATABLE_CONFIG } from '@simpl/element-ng/datatable';
import { parseFiltersFromParams } from '../main.filter';

const pFilterType = 'p';
@Component({
  standalone: false,
  selector: 'app-points',
  templateUrl: './points.component.html',
  styleUrls: ['./points.component.scss']
})

export class PointsComponent implements OnInit {
  @ViewChild('copyIdPointButton', { static: true }) copyIdPointButton!: TemplateRef<any>;
  @ViewChild('copyPointGroupId', { static: true }) copyPointGroupId!: TemplateRef<any>;
  @ViewChild('namespaceId', { static: true }) namespaceId!: TemplateRef<any>;

  p = 1;
  rows: any[] = [];
  myMap: MyMap[] = [];
  typeaheadData: string[] = [];
  columns!: TableColumn[];

  tableConfig = SI_DATATABLE_CONFIG;

  pointServiceSubscription?: Subscription;

  pgId!: string;
  filter = '';
  open!: boolean;
  filters: Filter[] = [];
  filtercolumns = new Map([['id', 'Key'], ['name', 'Name'], ['description', 'Description'], ['localPointRef', 'LocalPointRef'], ['namespace', 'Namespace']]);
  searchMode = 'id';

  constructor(
    public getPointService: GetPointsService,
    public getPointGroupService: GetPointGroupsService,
    public router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (this.getPointService.pointGroupsTable.size === 0) {
      this.getPointService.loading = true;
      this.getPointGroupService.getPointGroups();
    } else {
      this.getPointService.loading = false;
    }

    this.fillLocalTable(this.getPointService.pointsTable);

    this.route.queryParams.subscribe(params => {
      this.filters = parseFiltersFromParams(params, pFilterType);
      this.pgId = params.pId;
      this.pgId = this.filters[0]?.filterName;
      this.fillLocalTable(this.getPointService.pointsTable);
      this.doFilter(this.filters);
    });

    this.pointServiceSubscription = this.getPointService.pointsObserver.subscribe((value: Map<string, Point>) => {
      this.fillLocalTable(value);
      this.doFilter(this.filters);
    });

    this.initTableColumn();
    this.updateTypeaheadData();
  }

  fillLocalTable(value: Map<string, Point>) {
    if (this.pgId) {
      this.rows = Array.from(value).filter(point => {
        const group = this.getPointService.pointGroupsTable.get(this.pgId);
        if (group) {
          return group.pointIds.findIndex(id => id === point[0]) >= 0;
        } else {
          return false;
        }
      });
    } else {
      this.rows = Array.from(value);
    }

    this.myMap = this.rows.map(row => (
      {
        id: row[0],
        name: row[1].attributes.name,
        datatype: row[1].attributes.dataType,
        description: row[1].attributes.description,
        namespace: row[1].attributes.source.namespace,
        localPointRef: row[1].attributes.source.localPointRef,
        value: row[1].lastValue?.value,
        shortId: row[0].slice(0, 8)
      }));
    this.updateTypeaheadData();
  }

  getPointValues(pgId: string) {
    this.getPointService.getPointValue(pgId).subscribe((res: PointValues) => {

      res.data.forEach(element => {
        const point = this.getPointService.pointsTable.get(element.id);
        if (point) {
          point.attributes.lastValue = { value: element.attributes.lastValue.value };
        }
      });
      this.fillLocalTable(this.getPointService.pointsTable);
    });
  }

  getGroupId(pointId: string): string {
    let found = '';
    this.getPointService.pointGroupsTable.forEach((group, key) => {
      const position = group.pointIds.findIndex(id => id === pointId);
      if (position >= 0) {
        found = key;
      }
    });
    return found;
  }

  onTypeaheadSelect(event: any) {
    const selectedValue = event.option;
    if (selectedValue) {
      const newFilter: Filter = {
        status: 'default',
        filterName: selectedValue,
        title: this.filtercolumns.get(this.searchMode),
        description: selectedValue
      };
      this.filters.push(newFilter);
      this.doFilter(this.filters);
      this.filter = '';
    }
  }

  doFilter(filters: Filter[]) {
    this.fillLocalTable(this.getPointService.pointsTable);
    this.filters = filters;
    filters.forEach(filter => {
      this.myMap = this.myMap.filter(point => {
        const filterValue = filter.filterName.toLowerCase();
        if (point[this.findKey(filter.title)] == undefined) { return true; }
        return point[this.findKey(filter.title)].toLowerCase().includes(filterValue)
      });
    });
    this.updateQueryParams();
    this.updateTypeaheadData();
  }

  findKey(value?: string): string {
    let result = '';
    this.filtercolumns.forEach((label, key) => {
      if (label === value) {
        result = key;
      }
    });
    return result;
  }


  updateQueryParams() {
    const params: any = {
      pId: this.pgId
    };
    this.filters.forEach((filter, index) => {
      params[`filter${pFilterType}${index}`] = filter.filterName;
      params[`filter${pFilterType}Title${index}`] = filter.title;
    });
    if (this.filters.find(filter => filter.title === 'ID') == undefined) {
      params[`filter${pFilterType}${this.filters.length}`] = this.pgId;
      params[`filter${pFilterType}Title${this.filters.length}`] = 'ID';
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params
    });
  }

  adjustPage(page: number) {
    this.p = page;
  }

  numPages() {
    return Math.ceil(this.myMap.length / 10);
  }

  private initTableColumn(): void {
    this.columns = [
      {
        prop: 'id', //Key
        name: 'Key',
        width: 100,
        resizeable: true,
        canAutoResize: true,
        cellTemplate: this.copyIdPointButton
      },
      {
        prop: 'name', //Name
        name: 'Name',
        width: 100,
        resizeable: true,
        canAutoResize: true
      },
      {
        prop: 'description', //Description
        name: 'Description',
        width: 100,
        resizeable: true,
        canAutoResize: true
      },
      {
        prop: 'value', //Value
        name: 'Value',
        width: 100,
        resizeable: true,
        canAutoResize: true
      },
      {
        prop: 'localPointRef', //localPointRef
        name: 'LocalPointRef',
        width: 100,
        resizeable: true,
        canAutoResize: true
      },
      {
        prop: 'namespace', //Value
        name: 'Namespace',
        width: 100,
        resizeable: true,
        canAutoResize: true,
        cellTemplate: this.namespaceId
      },
      {
        prop: 'cov', //to go to COV Pages
        name: 'COV',
        width: 100,
        resizeable: true,
        canAutoResize: true,
        cellTemplate: this.copyPointGroupId
      }
    ];
  }
  private updateTypeaheadData() {
    const suggestions: string[] = [];
    if (this.filtercolumns.has(this.searchMode)) {
      this.myMap.forEach(point => {
        const value = point[this.searchMode as keyof MyMap];
        if (value) {
          suggestions.push(String(value));
        }
      });
    }
    this.typeaheadData = Array.from(new Set(suggestions));
  }
}

import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ServiceWorkerAuth } from '@building-x/common-ui-ng';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private serviceWorker: ServiceWorkerAuth,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) { }

  // Then adapt the changes as below :
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise<boolean>(async (resolve, reject) => {
      if (await this.serviceWorker.isAuthenticated()) {
        resolve(true);
        return;
      }
      this.router.navigate(['landing'], { relativeTo: this.route.root });
      resolve(false);
    });
  }
}

import { Component } from '@angular/core';

@Component({
  standalone: false,
  selector: 'app-point-relationship',
  templateUrl: './point-relationship.component.html',
  styleUrl: './point-relationship.component.scss'
})
export class PointRelationshipComponent {

}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { SiToastNotificationService, ToastStateName } from '@simpl/element-ng';
import { PointGroup } from 'src/app/model/point.models';
import { GetPointGroupsService } from 'src/app/services/get-point-groups.service';
import { GetPointsService } from 'src/app/services/get-points.service';

@Component({
  standalone: false,
  selector: 'app-point-group-modify',
  templateUrl: './point-group-modify.component.html',
  styleUrls: ['./point-group-modify.component.scss']
})
export class PointGroupModifyComponent implements OnInit {
  pointGroupName = '';
  pointGroupType = '';
  pointGroupId = '';
  selectedPointGroup?: PointGroup;
  newPointGroupName? = '';

  filter = '';
  typeaheadData: string[] = [];

  private toastNotificationService = inject(SiToastNotificationService);

  constructor(
    private getPointGroupsService: GetPointGroupsService,
    private getPointService: GetPointsService
  ) {}

  ngOnInit(): void {
    this.loadTypeaheadData();
    this.getPointGroupsService.getPointGroups();
  }

  loadTypeaheadData() {
    this.getPointGroupsService.getPointGroupData().subscribe({
      next: (response: any) => {
        const pointGroupIds = response.data.map((pg: any) => pg.id);
        const validPointGroups: string[] = [];
        const requests = pointGroupIds.map((pgID: string) =>
          this.getPointService.getPointsFirstPage(pgID).toPromise()
        );

        Promise.all(requests).then(results => {
          results.forEach((result, index) => {
            if (result.data && result.data.length > 0) {
              validPointGroups.push(pointGroupIds[index]);
            }
          });
          this.typeaheadData = validPointGroups;
        })
      }
    });
  }

  onTypeaheadSelect(event: any) {
    const pointGroup = this.getPointService.pointGroupsTable.get(event.text);
    if (pointGroup) {
      this.selectedPointGroup = pointGroup;
      this.newPointGroupName = pointGroup.pointGroupName;
      console.log('Received event:', event.text);
    } else {
      console.log('Received event:', event.text);
      console.warn('No Point Group found for the selected ID');
    }
  }

  createPointGroup() {
    if (this.pointGroupName.trim() && this.pointGroupType.trim() && this.pointGroupId.trim()) {
      this.getPointGroupsService.postPointGroup(this.pointGroupName, this.pointGroupType, this.pointGroupId).subscribe({
        next: (response: any) => {
          const pgID = response.data.id;
          const guid = 'generated-guid';
          this.showToast('success', 'Success', 'Point Group created successfully!', 7000);
          const newPointGroup: PointGroup = {
            pointGroupId: pgID,
            pointGroupName: this.pointGroupName,
            pointGroupType: this.pointGroupType,
            devices: false,
            pointCounts: 0,
            pointIds: [],
            loading: false
          };
          this.getPointService.pointGroupsTable.set(pgID, newPointGroup);

          this.getPointService.createPoint(pgID, guid).subscribe({
            next: () => {
              this.getPointGroupsService.getPointGroups();
            },
            error: () => {
              this.showToast('danger', 'Error', 'Point creation failed. Please try again.', 7000);
            }
          });
          this.pointGroupName = '';
          this.pointGroupType = '';
          this.pointGroupId = '';
        },
        error: err => {
          this.showToast('danger', 'Error', 'Point Group creation failed. Please try again.', 7000);
        }
      });
    }
  }

  savePointGroup() {
    if (this.selectedPointGroup) {
      const pointGroupId = this.selectedPointGroup.pointGroupId;
      this.getPointGroupsService.patchPointGroup(pointGroupId, this.newPointGroupName || '').subscribe({
        next: () => {
          const updatedPointGroup = this.getPointService.pointGroupsTable.get(pointGroupId);
          if (updatedPointGroup) {
            updatedPointGroup.pointGroupName = this.newPointGroupName || updatedPointGroup.pointGroupName;
            this.getPointService.pointGroupsTable.set(pointGroupId, updatedPointGroup);
          }
          console.log('Selected Point Group:', this.selectedPointGroup);
          this.showToast('success', 'Success', 'Point Group updated successfully.');
          this.getPointGroupsService.getPointGroups();
          this.resetForm();
        },
        error: () => {
          this.showToast('danger', 'Error', 'Failed to update Point Group.');
        }
      });
    } else {
      this.showToast('warning', 'Warning', 'Please select a Point Group to update.');
    }
  }

  deletePointGroup() {
    if (this.selectedPointGroup) {
      this.getPointGroupsService.deletePointGroup(this.selectedPointGroup.pointGroupId).subscribe({
        next: () => {
          if (this.selectedPointGroup) {
            this.getPointService.pointGroupsTable.delete(this.selectedPointGroup.pointGroupId);
          }
          console.log('Selected Point Group:', this.selectedPointGroup);
          this.showToast('success', 'Success', 'Point Group deleted successfully.');
          this.getPointGroupsService.getPointGroups();
          this.resetForm();
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === 400) {
            this.showToast('danger', 'Validation Error', 'automatically created point-groups can not be deleted');
          } else {
            this.showToast('danger', 'Error', 'Failed to delete Point Group.');
          }
        }
      });
    } else {
      this.showToast('warning', 'Warning', 'Please select a Point Group to delete.');
    }
  }

  resetForm() {
    this.filter = '';
    this.newPointGroupName = '';
  }

  showToast(
    state: ToastStateName,
    title: string,
    message: string,
    timeout?: number
  ): void {
    const toast = this.toastNotificationService.showToastNotification({
      state,
      title,
      message,
      timeout
    });
    toast.hidden?.subscribe();
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DateRange, Filter } from '@simpl/element-ng';
import { TableColumn } from '@siemens/ngx-datatable';
import { Subscription } from 'rxjs';

import { GetPointCovsService } from 'src/app/services/get-point-covs.service';
import { ActivatedRoute } from '@angular/router';
import { COVsValues, Point } from 'src/app/model/point.models';
import { parseFiltersFromParams } from '../main.filter';
import { SI_DATATABLE_CONFIG } from '@simpl/element-ng/datatable';
import { GetPointsService } from 'src/app/services/get-points.service';

const covFilterType = 'pc';
@Component({
  standalone: false,
  selector: 'app-cov',
  templateUrl: './cov.component.html',
  styleUrls: ['./cov.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class COVComponent implements OnInit {
  @ViewChild('qualityOfValue', { static: true }) qualityOfValue!: TemplateRef<any>;

  myDateRange: DateRange = {
    start: new Date(),
    end: new Date()
  };

  p = 1;
  columns!: TableColumn[];
  myMap: COVsValues[] = [];
  filters: Filter[] = [];
  loading?: boolean;
  pId!: string;
  point?: Point;
  showTable = true;
  covSubscription?: Subscription;

  tableConfig = SI_DATATABLE_CONFIG;

  constructor(
    public getCOV: GetPointCovsService,
    public getPoint: GetPointsService,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.initTableColumn();
    this.setInitialDateRange();
    this.toggleShowTable();
    this.route.queryParams.subscribe(params => {
      this.filters = parseFiltersFromParams(params, covFilterType);
      this.pId = this.filters[0].filterName;

      this.point = this.getPoint.pointsTable.get(this.pId);
      if (!this.point) {
        this.getPoint.getPoint(this.pId).subscribe(result => {
          this.point = { type: 'Point', id: this.pId, attributes: result };

          this.getCOV.getCovTableData(this.pId, this.myDateRange);
        });
      }
    })
    this.getCOV.getCovTableData(this.pId, this.myDateRange);
  }

  setInitialDateRange() {
    // Set end date to today
    this.myDateRange.end = new Date();

    // Set start date to two weeks before today
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
    this.myDateRange.start = twoWeeksAgo;
  }

  getPointCovData() {
    this.covSubscription = this.getCOV.pointCovObserver.subscribe((value: any) => {
      this.myMap = value.map((row: any) => ({
        createdAt: row.createdAt,
        targetValue: row.value,
        qualityOfValue: row.qualityOfValue
      }));
      this.changeDetectorRef.detectChanges();
    });
  }

  toggleShowTable() {
    this.showTable = this.showTable;
    this.getCOV.getCovTableData(this.pId, this.myDateRange);
    this.getPointCovData();
  }

  adjustPage(page: number) {
    this.p = page;
  }

  numPages() {
    return Math.ceil(this.myMap.length / 12);
  }

  private initTableColumn(): void {
    this.columns = [
      {
        prop: 'createdAt',
        name: 'Created at',
        width: 150,
        resizeable: true,
        canAutoResize: true
      },
      {
        prop: 'targetValue',
        name: 'Targeted Value',
        width: 120,
        resizeable: true,
        canAutoResize: true
      },
      {
        prop: 'qualityOfValue',
        name: 'Quality of Value',
        width: 50,
        resizeable: true,
        canAutoResize: true,
        cellTemplate: this.qualityOfValue
      }
    ];
  }
}

import { Component, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'app-short-id',
  templateUrl: './short-id.component.html',
  styleUrls: ['./short-id.component.scss']
})
export class ShortIDComponent {

  @Input() id = '';
  @Input() localPointRef = '';
  @Input() size = 8;

  copyToClipboard(input: string) {
    navigator.clipboard.writeText(input);
  }

}


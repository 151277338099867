import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { catchError, Observable, retry, throwError } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetDataService {

  constructor(private http: HttpClient) { }

  getUser(): Observable<any> {
    const url = environment.apiUrl + '/me';
    return this.http.get(url).pipe(
      retry(5),
      catchError(error => {
        console.error('Fehler:', error);
        return throwError(error);
      })
    );
  }

  getOrg(): Observable<any> {
    const url = environment.userApiUrl + '/me/customers';
    return this.http.get(url).pipe(
      retry(5),
      catchError(error => {
        console.error('Fehler:', error);
        return throwError(error);
      })
    );
  }
}

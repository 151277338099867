import { Component } from '@angular/core';
// import { environment } from 'src/environments/environment';

import { LocalisationService } from './services/localisation.service';

@Component({
  selector: 'app-root',
  standalone: false,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // cookieUrl = environment.cookieUrl;
  selectedValue: string;

  constructor(translate: LocalisationService) {
    this.selectedValue = translate.setLanguage();
    console.log("Newest Version 2.3")
  }
}

<h2 class="text-secondary ms-5 mt-5"> Devices </h2>

<app-app-table-filter
  [filters]="filters"
  [typeaheadData]="typeaheadData"
  [searchMode]="searchMode"
  [columns]="filtercolumns"
  [(filter)]="filter"
  [dataMap]="rows"
  [objectType]="'MyMap'"
  (typeaheadSelect)="onTypeaheadSelect($event)"
  (updateQueryparams)="updateQueryParams()"
  (doFilter)="doFilter($event)"
></app-app-table-filter>

<div class="p-5 d-flex flex-column">
  <ngx-datatable
  #table
  class="table-element elevation-1"
  [rows]="rows"
  [columns]="columns"
  [reorderable]="true"
  columnMode="force"
  [rowHeight]="tableConfig.rowHeightSmall"
  [limit]="10"
  [offset]="p - 1">
  </ngx-datatable>
</div>

<ng-template #copyIdPointButton let-value="value" let-row="row" ngx-datatable-cell-template>
  <app-short-id [id]="row.id"></app-short-id>
</ng-template>

<ng-template #PointTempl let-value="value" let-row="row" ngx-datatable-cell-template>
  <button type="button" class="btn btn-tertiary me-6"
    [routerLink]="['/main/point-group/']"
    [queryParams]="{
      filterpg0: 'Device-' + row.id,
      filterpgTitle0: 'ID'
      }"
    >Point Group</button>
</ng-template>

<ng-template #activationsKey let-value="value" let-row="row" ngx-datatable-cell-template>
  <app-short-id [id]="row.activationsKey" [size]="11"></app-short-id>
</ng-template>

<si-pagination navAriaLabel="second example" [(currentPage)]="p" [totalPages]="numPages()" (currentPageChange)="adjustPage($event)">
</si-pagination>

<div class="container" *ngIf="getDevices.loading">
  <si-loading-spinner class="inline-spinner" data-cy="users-loading-spinner"></si-loading-spinner>
</div>

import { Component } from '@angular/core';
import { AVAILABLE_LANGUAGES } from '@building-x/common-ui-ng';
import { environment } from 'src/environments/environment';


@Component({
  standalone: false,
  selector: 'app-landing',
  templateUrl: './landing.component.html'
})
export class LandingComponent {
  availableLangs = AVAILABLE_LANGUAGES;
  appId = 'APP.NAME';
  appName = environment.product;
}

import { Injectable } from '@angular/core';

import { Organization, SiCookiesService } from '@building-x/common-ui-ng';
import { MenuItem } from '@simpl/element-ng';

import { GetDataService } from './get-data.service';


@Injectable({
  providedIn: 'root'
})
export class OrgService {

  organization: Organization[] = [];
  selectedOrg?: string;
  name?: string;

  constructor(
    private getData: GetDataService,
    private cookieService: SiCookiesService
  ) { }

  navItems: MenuItem[] = [
    { title: 'NAV.PROFILE', icon: 'element-account', link: '/main/membership' },
    {
      title: 'NAV.SELECT_COMPANY', icon: 'element-project', items: []
    },
    { title: 'NAV.LOGOUT', icon: 'element-logout', link: '/logout' }
  ];

  getOrgInfo() {
    this.getData.getOrg().subscribe(res => {
      this.organization = res.data;
      this.navItems[1].items = [];
      res.data.map((org: any, i: number) => this.navItems[1].items?.push({ title: org.name, action: () => this.changeOrg(i) }));
    });
  }

  getOrganizations() {
    return this.getData.getOrg(); // Gibt das Observable zurück
  }

  changeOrg(i: number) {
    this.selectedOrg = this.organization[i].id;
    const orgname: string = this.organization[i].name!;
    this.name = orgname;
    window.location.reload();//prüfen obs gleiche gewählt ist.
    if (this.selectedOrg) {
      this.cookieService.setCookie('organizationId', this.selectedOrg);
    }
    this.cookieService.setCookie('organizationName', this.name);
    // if (this.selectedOrg !== this.cookieService.getCookie('organizaionId')) {
    //   window.location.reload();
    // }
  }
}

import { Filter } from "@simpl/element-ng";

export function parseFiltersFromParams(params: any, filterType: string): Filter[] {
  const filters: Filter[] = [];
  let i = 0;
  while (params[`filter${filterType}${i}`]) {
    const filterName = params[`filter${filterType}${i}`];
    const title = params[`filter${filterType}Title${i}`];
    filters.push({
      status: 'default',
      filterName,
      title,
      description: filterName
    });
    i++;
  }
  return filters;
}

<h2 class="text-secondary ms-5 mt-5"> Point Groups </h2>

<app-app-table-filter
  [filters]="filters"
  [typeaheadData]="typeaheadData"
  [searchMode]="searchMode"
  [columns]="filtercolumns"
  [(filter)]="filter"
  [dataMap]="pointGroupMyMap"
  [objectType]="'PointGroup'"
  (typeaheadSelect)="onTypeaheadSelect($event)"
  (updateQueryparams)="updateQueryParams()"
  (doFilter)="doFilter($event)"
></app-app-table-filter>

<div class="p-5 d-flex flex-column">
  <!-- Table to display point groups -->
  <ngx-datatable
    #table
    class="table-element elevation-1"
    siDatatableInteraction
    [rows]="pointGroupMyMap"
    [columns]="columns"
    [reorderable]="true"
    columnMode="force"
    [rowHeight]="tableConfig.rowHeightSmall"
    [limit]="10"
    [offset]="p - 1">
  </ngx-datatable>
</div>

<si-pagination [(currentPage)]="p" [totalPages]="numPages()" (currentPageChange)="adjustPage($event)"></si-pagination>

<div class="spinner-overlay" *ngIf="getPointGroupService.loading">
  <si-loading-spinner class="inline-spinner" data-cy="users-loading-spinner"></si-loading-spinner>
</div>

<ng-template #countTempl let-value="value" let-row="row" ngx-datatable-cell-template>
  <div *ngIf="value">{{value}}</div>
</ng-template>

<ng-template #copyPointGroupId let-value="value" let-row="row" ngx-datatable-cell-template>
  <app-short-id [id]="row.pointGroupId"></app-short-id>
</ng-template>

<ng-template #copyPointGroupName let-value="value" let-row="row" ngx-datatable-cell-template>
  <div>{{row.pointGroupName}}</div>
</ng-template>

<ng-template #copyIdPointButton let-value="value" let-row="row" ngx-datatable-cell-template>
  <button type="button" class="btn btn-tertiary me-6"
    [routerLink]="['/main/points/']"
    [queryParams]="{
      filterp0: row.pointGroupId,
      filterpTitle0: 'ID'
      }"
    >Point</button>
</ng-template>

<ng-template #loadingTemp let-row="row" ngx-datatable-cell-template>
  <button *ngIf="!row.loading"
    type="button"
    class="btn btn-circle btn-sm btn-secondary element-download me-6"
    aria-label="download"
    (click)="row.loading = true; getPointData(row.pointGroupId)">
  </button>
  <div class="spinner" *ngIf="row.loading">
    <si-loading-spinner class="small-spinner" data-cy="users-loading-spinner"></si-loading-spinner>
  </div>
</ng-template>

<ng-template #devicesTempl let-value="value" let-row="row" ngx-datatable-cell-template>
  <ng-container *ngIf="row.deviceType === 'device'; else otherType">
    <i class="element-router icon"></i>
  </ng-container>
  <ng-template #otherType>
    <ng-container *ngIf="row.deviceType === 'equipment'; else defaultType">
      <i class="element-set icon"></i>
    </ng-container>
    <ng-template #defaultType>
      <i class="element-group-lead icon"></i>
    </ng-template>
  </ng-template>
</ng-template>

import { SingleSignOnComponent } from '@building-x/common-ui-ng';

import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { environment } from 'src/environments/environment';
import { LogoutComponent } from './pages/logout/logout.component';
import { MainComponent } from './pages/main/main.component';
import { HomeComponent } from './pages/main/home/home.component';
import { AboutComponent } from './pages/main/about/about.component';
import { DevicesComponent } from './pages/main/devices/devices.component';
import { PointsComponent } from './pages/main/points/points.component';
import { PointGroupComponent } from './pages/main/point-group/point-group.component';
import { PointGroupModifyComponent } from './pages/main/point-group-modify/point-group-modify.component';
import { PointRelationshipComponent } from './pages/main/point-relationship/point-relationship.component';
import { COVComponent } from './pages/main/cov/cov.component';
import { LandingComponent } from './pages/landing/landing.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: 'landing', component: LandingComponent },
  { path: 'sso', component: SingleSignOnComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'main', component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'about', component: AboutComponent },
      { path: 'devices', component: DevicesComponent },
      { path: 'points', component: PointsComponent },
      { path: 'point-group', component: PointGroupComponent },
      { path: 'point-group-modify', component: PointGroupModifyComponent },
      { path: 'point-group-point-relationship', component: PointRelationshipComponent },
      { path: 'cov', component: COVComponent },
      { path: '**', redirectTo: 'home' }
    ]
  },
  { path: '**', redirectTo: 'main/home', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

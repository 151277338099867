import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { SiFilterBarModule, SiLandingPageModule, SiLoadingSpinnerModule, SimplElementNgModule, SiSearchBarModule, SiThemeService } from '@simpl/element-ng';
import { SiTranslateNgxTModule } from '@simpl/element-ng/ngx-translate';
import {
  OrganizationProfile,
  ServiceWorkerAuth, SiAODSModule, SiAppDataService,
  SiHttpErrorInterceptor,
  SiLoginAuthModule, SiSharedModule, SiThemingService,
  SiUOModule,
  SiVirtualAssistantModule
} from '@building-x/common-ui-ng';
import { SessionService } from './services/session.service';
import { OrganizationChangeService } from './services/organization-change.service';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './guards/auth.guard';

import { LogoutComponent } from './pages/logout/logout.component';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { LandingComponent } from './pages/landing/landing.component';
import { AppRoutingModule } from './app.routes';
import { CdkConnectedOverlay, CdkOverlayOrigin, OverlayModule } from '@angular/cdk/overlay';
import { A11yModule } from '@angular/cdk/a11y';
import { FormsModule } from '@angular/forms';
import { SiApplicationHeaderComponent, SiHeaderAccountItemComponent, SiHeaderActionItemComponent, SiHeaderActionsDirective, SiHeaderBrandDirective, SiHeaderCollapsibleActionsComponent, SiHeaderNavigationComponent, SiHeaderNavigationItemComponent, SiHeaderSiemensLogoComponent } from '@simpl/element-ng/application-header';
import { SiHeaderDropdownComponent, SiHeaderDropdownItemComponent, SiHeaderDropdownTriggerDirective } from '@simpl/element-ng/header-dropdown';
import { NgxDatatableModule } from '@siemens/ngx-datatable';
import { AboutComponent } from './pages/main/about/about.component';
import { AppTableFilterComponent } from './pages/main/components/app-table-filter/app-table-filter.component';
import { HomeComponent } from './pages/main/home/home.component';
import { MainComponent } from './pages/main/main.component';
import { DevicesComponent } from './pages/main/devices/devices.component';
import { PointsComponent } from './pages/main/points/points.component';
import { PointGroupComponent } from './pages/main/point-group/point-group.component';
import { PointGroupModifyComponent } from './pages/main/point-group-modify/point-group-modify.component';
import { PointRelationshipComponent } from './pages/main/point-relationship/point-relationship.component';
import { COVComponent } from './pages/main/cov/cov.component';
import { ShortIDComponent } from './components/short-id/short-id.component';

const initializeSrvWorker = (serviceWorkerAuth: ServiceWorkerAuth) => async (): Promise<void> => {
  const loginData = {
    openIdConnectProvider: environment?.issuer,
    clientId: environment?.clientId,
    customerIdpBaseUrl: environment?.userApiUrl,
    logoutBaseUrl: environment?.siemensIdBaseUrl,
    extras: {
      audience: environment?.audience
    }
  };
  const swVariables = {
    oidcDomains: [environment.issuer, environment.msIssuer]
  };
  await serviceWorkerAuth.loadServiceWorker(loginData, swVariables);
};

export const createTranslateLoader = (http: HttpClient) => new MultiTranslateHttpLoader(http, [
  { prefix: './assets/i18n/', suffix: `.json` }
]);

@NgModule({
  declarations: [
    AppComponent,
    LogoutComponent,
    LandingComponent,
    AboutComponent,
    AppTableFilterComponent,
    HomeComponent,
    MainComponent,
    DevicesComponent,
    PointsComponent,
    PointGroupComponent,
    PointGroupModifyComponent,
    PointRelationshipComponent,
    COVComponent,
    ShortIDComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    SiLoadingSpinnerModule,
    SiSearchBarModule,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    OverlayModule,
    A11yModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    SimplElementNgModule,
    SiSearchBarModule,
    SiFilterBarModule,
    SiLoginAuthModule,
    SiLoadingSpinnerModule,
    SiApplicationHeaderComponent,
    SiHeaderAccountItemComponent,
    SiHeaderActionItemComponent,
    SiHeaderActionsDirective,
    SiHeaderBrandDirective,
    SiHeaderCollapsibleActionsComponent,
    SiHeaderNavigationComponent,
    SiHeaderNavigationItemComponent,
    SiHeaderSiemensLogoComponent,
    SiHeaderDropdownComponent,
    SiHeaderDropdownItemComponent,
    SiHeaderDropdownTriggerDirective,
    SiSharedModule,
    NgxDatatableModule,
    TranslateModule,
    SiTranslateNgxTModule,
    SiVirtualAssistantModule.forRoot({
      apiUrl: environment.copilotApiUrl
    }),
    SiAODSModule.forRoot({
      apiUrl: `${environment.userApiUrl}/me`
    }),
    HttpClientModule,
    RouterModule,
    SiLandingPageModule,
    SiUOModule.forRoot(environment),
    SiLoginAuthModule,
    SiTranslateNgxTModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    RouterModule,
    TranslateModule
  ],
  providers: [
    AuthGuard,
    ServiceWorkerAuth,
    SiAppDataService,
    OrganizationProfile,
    PointGroupComponent,
    SiThemeService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeSrvWorker,
      multi: true,
      deps: [ServiceWorkerAuth]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SiHttpErrorInterceptor,
      multi: true
    },
    { provide: 'siProfileConfig', useValue: environment },
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    OrganizationChangeService,
    SiAppDataService,
    SiThemingService //Neu
  ],
  bootstrap: [AppComponent]
})


export class AppModule {
  constructor(sessionService: SessionService) {
    sessionService.initialize();
  }
}

import { Component, OnInit } from '@angular/core';
import { ServiceWorkerAuth } from '@building-x/common-ui-ng';

@Component({
  standalone: false,
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent implements OnInit {
  constructor(private readonly serviceWorker: ServiceWorkerAuth) { }

  ngOnInit(): void {
    this.serviceWorker.logoutOnMessage();
  }
}

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TableColumn } from '@siemens/ngx-datatable';
import { Filter } from '@simpl/element-ng';
import { SI_DATATABLE_CONFIG } from '@simpl/element-ng/datatable';
import { Subscription } from 'rxjs';

import { GetDeviceService } from 'src/app/services/get-device.service';
import { parseFiltersFromParams } from '../main.filter';

const deviceFilterType = 'd';
@Component({
  standalone: false,
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {

  @ViewChild('copyIdPointButton', { static: true }) copyIdPointButton!: TemplateRef<any>;
  @ViewChild('PointTempl', { static: true }) PointTempl!: TemplateRef<any>;
  @ViewChild('activationsKey', { static: true }) activationsKey!: TemplateRef<any>;

  p = 1;
  rows: any[] = [];
  typeaheadData: string[] = [];
  columns!: TableColumn[];

  tableConfig = SI_DATATABLE_CONFIG;

  pointServiceSubscription?: Subscription;

  filter = '';
  open!: boolean;
  filters: Filter[] = [];
  filtercolumns = new Map([['id', 'ID'], ['deviceType', 'Device Type'], ['deviceName', 'Custom Name'], ['activationsKey', 'Activation Key'], ['serialNumber', 'Serialnumber']]);
  searchMode = 'id';

  constructor(
    public getDevices: GetDeviceService,
    public router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.filters = parseFiltersFromParams(params, deviceFilterType);
      this.fillLocalTable();
      this.doFilter(this.filters);
    });

    this.getDevices.getDeviceData();
    this.pointServiceSubscription = this.getDevices.observer.subscribe((value: any) => {
      this.rows = Array.from(value);
      this.fillLocalTable();
      this.getDevices.loading = false;
    });
    this.initTableColumn();
    this.updateTypeaheadData();
  }

  fillLocalTable() {
    this.rows = this.getDevices.deviceData.map(row => (
      {
        deviceType: row.attributes.modelName,
        serialNumber: row.attributes.serialNumber,
        activationsKey: row.attributes.activationKey,
        deviceName: row.attributes.profile.name,
        id: row.id, //use this to copy the id
        shortId: row.id.slice(0, 8)
      }));
    this.updateTypeaheadData();
  }

  onTypeaheadSelect(event: any) {
    const selectedValue = event.option;
    if (selectedValue) {
      const newFilter: Filter = {
        status: 'default',
        filterName: selectedValue,
        title: this.filtercolumns.get(this.searchMode),
        description: selectedValue
      };
      this.filters.push(newFilter);
      this.doFilter(this.filters);
      this.filter = '';
    }
  }

  findKey(value?: string): string {
    let result = '';
    this.filtercolumns.forEach((label, key) => {
      if (label === value) {
        result = key;
      }
    });
    return result;
  }

  doFilter(filters: Filter[]) {
    this.fillLocalTable();
    this.filters = filters;
    filters.forEach(filter => {
      this.rows = this.rows.filter(device => {
        const filterValue = filter.filterName.toLowerCase();
        return device[this.findKey(filter.title)].toLowerCase().includes(filterValue);
      });
    });
    this.updateQueryParams();
    this.updateTypeaheadData();
  }

  updateQueryParams() {
    const params: any = {};
    this.filters.forEach((filter, index) => {
      params[`filter${deviceFilterType}${index}`] = filter.filterName;
      params[`filter${deviceFilterType}Title${index}`] = filter.title;
    });

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params
    });
  }

  adjustPage(page: number) {
    this.p = page;
  }

  numPages() {
    return Math.ceil(this.rows.length / 10);
  }

  private initTableColumn(): void {
    this.columns = [
      {
        prop: 'id',
        name: 'Id',
        width: 50,
        resizeable: true,
        canAutoResize: true,
        cellTemplate: this.copyIdPointButton
      },
      {
        prop: 'deviceType',
        name: 'DeviceType',
        width: 80,
        resizeable: true,
        canAutoResize: true
      },
      {
        prop: 'deviceName',
        name: 'CustomName',
        resizeable: true,
        canAutoResize: true
      },
      {
        prop: 'activationsKey',
        name: 'ActivationKey',
        minWidth: 120,
        resizeable: true,
        canAutoResize: true,
        cellTemplate: this.activationsKey
      },
      {
        prop: 'serialNumber',
        name: 'Serialnumber',
        minWidth: 100,
        resizeable: true,
        canAutoResize: true
      },
      {
        prop: 'PointTempl',
        name: 'PointGroup',
        minWidth: 60,
        resizeable: true,
        canAutoResize: true,
        cellTemplate: this.PointTempl
      }
    ];
  }

  copyToClipboard(input: string) {
    navigator.clipboard.writeText(input);
  }

  trackByIdentity(index: number, item: any) {
    return item;
  }

  private updateTypeaheadData() {
    const suggestions: string[] = [];
    if (this.filtercolumns.has(this.searchMode)) {
      this.rows.forEach(device => {
        const value = device[this.searchMode as keyof typeof this.rows[0]];
        if (value) {
          suggestions.push(String(value));
        }
      });
    }
    this.typeaheadData = Array.from(new Set(suggestions));
  }
}

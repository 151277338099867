<div class="has-navbar-fixed-top">
<si-application-header>
  <si-header-brand>
    <a si-header-siemens-logo routerLink="/" aria-label="Siemens" class="d-none d-md-flex"></a>
    <span class="application-name">{{"APP.NAME" | translate}}</span>
  </si-header-brand>

  <si-header-actions>
    <si-header-collapsible-actions>
      <button
        si-header-action-item
        type="button"
        icon="element-help"
        [badge]="0"
        [siHeaderDropdownTriggerFor]="support"
        >HelpItem</button>
    </si-header-collapsible-actions>
    <button
      si-header-account-item
      type="button"
      [name]='(currentUser?.identity?.fullName)'
      [siHeaderDropdownTriggerFor]="account">
    </button>
  </si-header-actions>
</si-application-header>
</div>
<ng-template #account>
  <si-header-dropdown>
    <div class="mx-5">
      <div class="fw-bold">{{currentUser?.identity?.fullName}}</div>
      <div>{{currentUser?.identity?.email}}</div>
      <div class="d-flex align-items-center text-secondary mt-2">
        {{this.organizationsName}}
        <span class="badge bg-default">Admin</span>
      </div>
    </div>
    <div class="dropdown-divider"></div>
    <button si-header-dropdown-item icon="element-account" type="button" routerLink="/main/home">{{"NAV.PROFILE" | translate}}</button>
    <button
      si-header-dropdown-item
      icon="element-project"
      type="button"
      [siHeaderDropdownTriggerFor]="company">
      {{"NAV.SELECT_COMPANY" | translate}}
    </button>
    <button
      si-header-dropdown-item
      icon="element-language"
      type="button"
      [siHeaderDropdownTriggerFor]="language">
      {{"LANGUAGE.LANGUAGE" | translate}}
    </button>
    <button
      si-header-dropdown-item
      icon="element-palette"
      type="button"
      [siHeaderDropdownTriggerFor]="theme">
      {{"THEME.THEME" | translate}}
    </button>
    <button si-header-dropdown-item icon="element-logout" type="button"routerLink="/logout">{{"NAV.LOGOUT" | translate}}</button>
  </si-header-dropdown>
</ng-template>
<si-navbar-vertical [items]="menuItems" navbarCollapseButtonText="collapse" navbarExpandButtonText="expand">
  <div class="si-layout-content-padding">
    <router-outlet></router-outlet>
  </div>
</si-navbar-vertical>

<ng-template #language>
  <si-header-dropdown>
    <button si-header-dropdown-item type="button" (click)="changeLanguage('de')">{{"LANGUAGE.DE" | translate}}</button>
    <button si-header-dropdown-item type="button" (click)="changeLanguage('en')">{{"LANGUAGE.EN" | translate}}</button>
    <button si-header-dropdown-item type="button" (click)="changeLanguage('fr')">{{"LANGUAGE.FR" | translate}}</button>
  </si-header-dropdown>
</ng-template>

<ng-template #company>
  <si-header-dropdown>
    <button
      *ngFor="let company of organizations"
      si-header-dropdown-item
      type="button"
      (click)="selectCompany(company)">
      {{ company.attributes.name }}
    </button>
  </si-header-dropdown>
</ng-template>

<ng-template #theme>
  <si-header-dropdown>
    <button si-header-dropdown-item type="button" (click)="applyThemeType('auto')">{{"THEME.AUTO" | translate}}</button>
    <button si-header-dropdown-item type="button" (click)="applyThemeType('light')">{{"THEME.LIGHT" | translate}}</button>
    <button si-header-dropdown-item type="button" (click)="applyThemeType('dark')">{{"THEME.DARK" | translate}}</button>
  </si-header-dropdown>
</ng-template>

<ng-template #support>
  <si-header-dropdown>
    <button si-header-dropdown-item type="button" routerLink="/main/about">{{"NAV.ABOUT" | translate}}</button>
    <a si-header-dropdown-item href="http://www.siemens.com/corporate-information" target="_blank">{{"Corporate Information"}}</a>
  </si-header-dropdown>
</ng-template>

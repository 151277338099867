import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { COVs } from '../model/point.models';

import { SiCookiesService } from '@building-x/common-ui-ng';

import { DateRange } from '@simpl/element-ng';
import { catchError, retry, Subject, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class GetPointCovsService {

  arrayForRow: any[] = [];
  pointCovObserver = new Subject();

  constructor(
    private http: HttpClient,
    private cookiesService: SiCookiesService
  ) { }

  getPointCov(pointID: string, dateRange?: DateRange, covPageCursor?: string) {
    if (!dateRange) {
      const now = new Date();
      dateRange = {
        start: new Date(now.setDate(now.getDate() - 14)),
        end: new Date()
      };
    }
    // checks if end is empty or not
    if (!dateRange.end) {
      dateRange.end = new Date();
    }
    // checks if start is empty or not
    if (!dateRange.start) {
      dateRange.start = new Date(dateRange.end.setDate(dateRange.end.getDate() - 14));
    }
    //checks if dateRange is empty or not

    const orgId = this.cookiesService.getCookie('organizationId');
    const dateStart = dateRange.start.toISOString();
    const adjustedEndDate = new Date(dateRange.end);
    adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);
    const dateEnd = adjustedEndDate.toISOString();
    const apiEnv = environment.pointApiUrlV2;

    let url = `${apiEnv}/organizations/${orgId}/points/${pointID}/covs?startDateTime=${dateStart}&endDateTime=${dateEnd}`;

    if (covPageCursor) {
      const uri = encodeURIComponent(covPageCursor);
      url = `${apiEnv}/organizations/${orgId}/points/${pointID}/covs?page[after]=${uri}&startDateTime=${dateStart}&endDateTime=${dateEnd}`;
    }

    //logs out the same Date
    return this.http.get<COVs>(url).pipe(
      retry(5),
      catchError(error => {
        console.error('Fehler:', error);
        return throwError(error);
      })
    );
  }

  //gets more COV if one exists
  getCovTableData(pointId: string, dateRange?: DateRange) {
    this.getPointCov(pointId, dateRange).subscribe((res: COVs) => {
      this.arrayForRow = res.covs;
      this.pointCovObserver.next(this.arrayForRow);
      this.pointCovPagination(pointId, res);
    });
  }

  pointCovPagination(pointId: string, res: COVs, dateRange?: DateRange) {
    // this.pointCovObserver.next(this.arrayForRow);
    const covPageCursor = res.paginationCursor;
    if (covPageCursor) {
      this.getPointCov(pointId, dateRange, covPageCursor).subscribe((result: COVs) => this.pointCovPagination(pointId, result, dateRange));
    }
  }

}

<h2 class="text-secondary ms-5 mt-5"> COVs</h2>
<div class="d-flex flex-column flex-xl-row">
<div class="boxSize">
<div class="w100 mt-5 ms-5 me-5">
  <ul class="list-group">
    <li class="list-group-item"><strong>Name:</strong> {{ point?.attributes?.name }}</li>
    <li class="list-group-item"><strong>Description:</strong> {{ point?.attributes?.description }}</li>
    <li class="list-group-item"><strong>Unit:</strong> {{ point?.attributes?.unit }}</li>
    <li class="list-group-item"><strong>Start:</strong> {{myDateRange.start | date: 'mediumDate'}}</li>
    <li class="list-group-item"><strong>End:</strong> {{myDateRange.end | date: 'mediumDate'}}</li>
  </ul>
</div>

  <div class="d-flex ms-5">
    <si-datepicker
    [(dateRange)]="myDateRange"
    [config]="{ enableDateRange: true }"
    class="d-inline-block noshrink"
    ></si-datepicker>
    <div class="bg-base-1 w-100 me-5 ">
    </div>
    </div>

    <div class="d-flex mb-5 justify-content-end">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 mt-3 me-5 d-flex justify-content-end">
      <button
      *ngIf="showTable"
        type="button"
        (click)='toggleShowTable()'
        class="btn btn-secondary ms-5"
      >Set</button>
    </div>
    </div>
  </div>
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 p-5 d-flex flex-column">
  <si-loading-spinner *ngIf="loading"></si-loading-spinner>
  <ngx-datatable
    class="table-element elevation 1"
    [rows]="myMap"
    [columns]="columns"
    [reorderable]="true"
    columnMode="force"
    [rowHeight]="tableConfig.rowHeightSmall"
    [limit]="12"
    [offset]="p - 1"
  ></ngx-datatable>
  <si-pagination
    [(currentPage)]="p"
    [totalPages]="numPages()"
    (currentPageChange)="adjustPage($event)"
  ></si-pagination>
</div>
<ng-template #qualityOfValue let-value="value" let-row="row" ngx-datatable-cell-template>
  <ng-container *ngIf="value">
    {{ value }}
    <si-icon icon="element-validation-warning" color="status-danger" size="display-4" />
  </ng-container>
  <ng-container *ngIf="value === 0">
    {{ value }}
  </ng-container>
</ng-template>
</div>

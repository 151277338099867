import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarItem } from '@simpl/element-ng';
import { ServiceWorkerAuth } from '@building-x/common-ui-ng';
import { ReplaySubject } from 'rxjs';

export interface AccountInfo {
  title?: string;
  image?: string;
}
export let appList: NavbarItem[] = [];

@Injectable({
  providedIn: 'root'
})

export class SessionService {
  public profileUrl = 'main/profile';
  public standardPlan = 'main/standard-plan';
  protected initialized$ = new ReplaySubject<boolean>(1);
  private readonly whiteListURLs = ['landing', 'logout'];

  constructor(
    private readonly router: Router,
    private readonly serviceWorker: ServiceWorkerAuth
  ) { }

  async initialize(): Promise<void> {
    this.setURLParams();
    if (await this.serviceWorker.isAuthenticated()) {
      if (window.location.href.toString().includes('/sso')) {
        // handle SSO when user already logged In (older session present)
        await this.handleSSO();
      } else {
        this.navigateToEntryString();
      }
    } else if (this.startedWithDeepLink()) {
      await this.serviceWorker.makeAuthRequest();
    } else if (window.location.href.toString().includes('/sso')) {
      // handle SSO (new session)
      await this.handleSSO();
    }
  }

  private setURLParams() {
    let param = window.location.hash;
    if (param.length > 1 && !this.whiteListURLs.find(ele => param.includes(`#/${ele}`))) {
      switch (true) {
        case param.includes('#/sso/standard-plan'):
          param = this.standardPlan;
          break;

        // handles custom navigation path set by apps
        case param.includes('#/sso/main/'):
          param = this.setCustomNavigations(param);
          break;

        // handles navigation to membership page
        case param.includes('#/sso/myprofile'):
          param = this.setProfileNavigation(param);
          break;

        case param.includes('#/sso'):
          param = param.replace('#/sso', '');
          break;

        case param.startsWith('#/'):
          param = param.substring(2);
          break;

        default:
          break;
      }
      sessionStorage.setItem('entryPointUrl', param);
    }
  }

  private setProfileNavigation(param: string) {
    param = param.replace('#/sso/myprofile', '');
    window.history.replaceState(null, '', window.location.hash.replace('/sso/myprofile', '/sso'));
    sessionStorage.setItem('isMyProfile', 'true');
    return param;
  }

  // setting navigation url when apps passes specific url in session storage
  private setCustomNavigations(param: string) {
    const dynamicPath = param.split('#/sso/main/')[1]?.split('?')[0];
    param = param.replace(`#/sso/main/${dynamicPath}`, '');
    window.history.replaceState(null, '', window.location.hash.replace(`/sso/main/${dynamicPath}`, '/sso'));
    sessionStorage.setItem('navigateToCustomUrl', dynamicPath);
    return param;
  }

  async handleSSO() {
    await this.serviceWorker.makeAuthRequest();
    this.navigateToEntryString();
  }

  hasValidIdentity() {
    return sessionStorage.getItem('identitySet') === 'true';
  }

  private navigateToEntryString() {
    const redirectUrl = sessionStorage.getItem('entryPointUrl');
    if (redirectUrl) {
      sessionStorage.removeItem('entryPointUrl');
      this.router.navigateByUrl(redirectUrl);
    } else {
      this.router.navigate(['/']);
    }
  }

  async getAccountInfo() {
    const accountData = await this.serviceWorker.getAccountInfo();
    return {
      title: accountData?.value?.name,
      image: accountData?.value?.picture
    };
  }


  private startedWithDeepLink(): boolean {
    const path = sessionStorage.getItem("entryPointUrl");
    return !!path && path.length > 0;
  }

  setAppList(apps: any) {
    appList = apps;
  }
}

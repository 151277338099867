import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { SiCookiesService } from '@building-x/common-ui-ng';
import { catchError, retry, Subject, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class GetDeviceService {

  loading?: boolean;
  deviceData: any[] = [];
  observer = new Subject();

  constructor(
    private http: HttpClient,
    private cookieService: SiCookiesService
  ) { }

  getDevices(devicePageCursor?: string) {
    const orgId = this.cookieService.getCookie('organizationId');
    let url = `${environment.deviceApiUrl}/partitions/${orgId}/devices`;
    if (devicePageCursor) {
      url = `${environment.deviceApiUrl}/partitions/${orgId}/devices?page[after]=${encodeURIComponent(devicePageCursor)}`;
    }
    return this.http.get(url).pipe(
      retry(5),
      catchError(error => {
        console.error('Fehler:', error);
        return throwError(error);
      })
    );
  }

  getDeviceData() {
    this.deviceData = [];
    this.getDevices().subscribe((res: any) => this.getDevicePagination(res));

  }

  getDevicePagination(res: any) {
    if (this.deviceData) {
      this.deviceData.push(...res.data);
    } else {
      this.deviceData = res.data;
    }

    this.observer.next(this.deviceData);
    const devicePageCursor = res.meta?.page?.cursor;
    if (devicePageCursor) {
      this.getDevices(devicePageCursor).subscribe(result => this.getDevicePagination(result));
    }
  }

}

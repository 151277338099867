export const environment = {
  production: true,
  useHash: true,
  product: 'Point UI',
  test: false,
  audience: 'https://horizonint.cloud',
  siemensIdBaseUrl: 'https://login.siemens.com',
  profileUrl: 'https://uss.login.siemens.com/userprofile',
  apiUrl: 'https://prodint.um.user.horizonint.cloud/api/v2',
  userApiUrl: 'https://um.user.horizonint.cloud/api/v2',
  deviceApiUrl: 'https://dm.device.horizonint.cloud/api/v2',
  pointApiUrlV2: 'https://pv.point.horizonint.cloud/api/v2',
  pointApiUrlV3: 'https://pv.point.horizonint.cloud/api/v3',
  licenseApiUrl: 'https://subscription.horizonint.cloud/int/api',
  clientId: 'UcU4S7fZzkoHlZ1qoWhcYseV8YGCO4fF',
  issuer: 'https://siemens-qa-bt-015.eu.auth0.com/',
  copilotApiUrl: 'https://userguide-int-api.data-analytics.horizonint.cloud',
  msIssuer: 'https://btprodeu.piam.eu1.mindsphere.io/oauth/token',
  whiteListedApis: [
    'https://user.horizonint.cloud/api/v1',
    'https://um.user.horizonint.cloud/api/v2',
    'https://prodint.um.user.horizonint.cloud/api/v2',
    'https://subscription.horizonint.cloud/api/v1',
    'https://subscription.horizonint.cloud/api/v2',
    'https://int.webapp.horizonint.cloud/api/v1/me/apps',
    'https://site.horizonint.cloud',
    'https://aods.horizonint.cloud/api/v2',
    'https://platform.notification.horizonint.cloud/api/v1',
    'https://btprodeu.piam.eu1.mindsphere.io/oauth/token',
    'https://uss.login-qa.siemens.com/userprofile',
    'https://prodint.user.horizonint.cloud/api/v1',
    'https://um.user.horizonint.cloud/api/v2',
    'https://product-int-dm.device.horizonint.cloud/api/v2',
    'https://product-int.point.horizonint.cloud/api/v2',
    'https://product-int.point.horizonint.cloud/api/v3',
    'https://prodint.um.user.horizonint.cloud/api/v2',
    'https://product-int.subscription.horizonint.cloud/api/v1',
    'https://product-dev.webapp.horizonint.cloud/api/v1/me/apps',
    'https://site.horizonint.cloud',
    'https://product-int.aods.horizonint.cloud/api/v2',
    'https://platform.notification.horizonint.cloud/api/v1',
    'https://product-int.subscription.horizonint.cloud/api/v2',
    'https://siemens-devint.subscription-suite.io'
  ]
};

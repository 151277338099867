import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { SiCookiesService } from '@building-x/common-ui-ng';

import { environment } from 'src/environments/environment';

import { GetPointResult, Point, PointValues } from 'src/app/model/point.models';
import { catchError, retry, Subject, throwError } from 'rxjs';
import { PointGroup } from '../model/point.models';

@Injectable({
  providedIn: 'root'
})
export class GetPointsService {

  //map for points
  pointsTable = new Map<string, Point>();
  pointsObserver = new Subject<Map<string, Point>>();
  //map for pointGroups
  pointGroupsTable = new Map<string, PointGroup>();

  loading?: boolean;

  constructor(
    private http: HttpClient,
    private cookiesService: SiCookiesService
  ) { }

  createPoint(pgID: string, guid: string) {
    const partitionsId = this.cookiesService.getCookie('organizationId');
    const url = `${environment.pointApiUrlV3}/partitions/${partitionsId}/point-groups/${pgID}/points`;
    const accessToken = this.cookiesService.getCookie('accessToken');
    const headers = new HttpHeaders({
      "Authorization": `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    });

    const body = {
      data: {
        type: 'Point',
        attributes: {
          dataType: 'number',
          description: `TestPoint-${guid}`,
          name: `TestPoint`
        }
      }
    };
    return this.http.post<any>(url, body, { headers }).pipe(
      retry(5),
      catchError(error => {
        console.error('Fehler:', error);
        return throwError(error);
      })
    );
  }

  getPoint(pId: string) {
    const organizationId = this.cookiesService.getCookie('organizationId');
    const url = `${environment.pointApiUrlV2}/organizations/${organizationId}/points/${pId}`;
    return this.http.get<any>(url).pipe(
      retry(5),
      catchError(error => {
        console.error('Fehler:', error);
        return throwError(error);
      })
    );
  }
  getPointsData(pgID: string, pointPageCursor?: string) { //pgID: string --> parameter
    const partitionsId = this.cookiesService.getCookie('organizationId');
    let url = `${environment.pointApiUrlV3}/partitions/${partitionsId}/point-groups/${pgID}/points`;
    if (pointPageCursor) {
      const uri = encodeURIComponent(pointPageCursor);
      url = `${environment.pointApiUrlV3}/partitions/${partitionsId}/point-groups/${pgID}/points?page[after]=${uri}`;
    }
    return this.http.get<GetPointResult>(url).pipe(
      retry(5),
      catchError(error => {
        console.error('Fehler:', error);
        return throwError(error);
      })
    );
  }

  getPointsFirstPage(pgID: string) { //pgID: string --> parameter
    const partitionsId = this.cookiesService.getCookie('organizationId');
    const url = `${environment.pointApiUrlV3}/partitions/${partitionsId}/point-groups/${pgID}/points?page[limit]=1`;
    return this.http.get<GetPointResult>(url).pipe(
      retry(5),
      catchError(error => {
        console.error('Fehler:', error);
        return throwError(error);
      })
    );
  }
  //get point values
  getPointValue(pgID: string) {
    const partitionsId = this.cookiesService.getCookie('organizationId');
    const url = `${environment.pointApiUrlV3}/partitions/${partitionsId}/point-groups/${pgID}/points/lastValues`;
    return this.http.get<PointValues>(url).pipe(
      retry(5),
      catchError(error => {
        console.error('Fehler:', error);
        return throwError(error);
      })
    );
  }

  getPointFromPointGroup(pointGroupId: string) {
    if (this.pointGroupsTable?.get(pointGroupId)) {
      this.pointGroupsTable.get(pointGroupId)!.pointIds = [];
    }
    this.getPointsData(pointGroupId).subscribe((result: GetPointResult) => {
      this.getPointPagination(pointGroupId, result);
      /*if (result.meta?.page?.cursor) {
        this.getPointPagination(pointGroupId, result);
      } else {
        this.pointGroupsTable.get(pointGroupId)!.pointIds.push(...result.data.map(point => point.id));
        this.pointGroupsTable.get(pointGroupId)!.loading = false;
        this.pointsObserver.next(this.pointsTable);
      }*/
    });
  }

  getPointPagination(pointGroupId: string, result: GetPointResult) {
    const group = this.pointGroupsTable?.get(pointGroupId);
    if (group) {
      group.pointIds.push(...result.data.map(point => point.id));
      group.loading = !!result.meta?.page?.cursor;
      group.devices = true; //änderung Devices
    }

    result.data.forEach(element => {
      this.pointsTable.set(element.id, element);
    });

    const pointPageCursor = result.meta?.page?.cursor;

    if (!pointPageCursor) {
      const pointGroup = this.pointGroupsTable.get(pointGroupId);
      if (pointGroup) {
        pointGroup.loading = false;
      }
    }

    this.pointsObserver.next(this.pointsTable);

    if (pointPageCursor) {
      this.getPointsData(pointGroupId, pointPageCursor).subscribe((res: any) => {
        this.getPointPagination(pointGroupId, res);
      });
    } else {
      this.loading = false;
    }
  }
}

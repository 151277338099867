
import { Component, OnInit } from '@angular/core';
import { SiCookiesService } from '@building-x/common-ui-ng';
// import { OrgService } from 'src/app/services/org.service';

@Component({
  standalone: false,
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

  organizationName?: string;
  organizationId?: string;

  constructor(
    private cookieService: SiCookiesService
    // private organizationService: OrgService
  ) { }

  ngOnInit(): void {
    this.organizationName = this.cookieService.getCookie('organizationName');
    // this.organizationName = this.organizationService.name;
    this.organizationId = this.cookieService.getCookie('organizationId');
    // this.organizationId = this.organizationService.selectedOrg;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { SiCookiesService } from '@building-x/common-ui-ng';
import { GetPointsService } from './get-points.service';
import { catchError, retry, Subject, throwError } from 'rxjs';
import { PointGroup, PointGroupResult, PostPointGroup } from '../model/point.models';


@Injectable({
  providedIn: 'root'
})
export class GetPointGroupsService {
  p = 1;
  _loading?: boolean;
  observer = new Subject();
  subscriptionCheck = false;
  pointGroupsObserver = new Subject<Map<string, PointGroup>>();
  activeRequests = 0;

  get loading() {
    return !!this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
  }

  constructor(
    private http: HttpClient,
    private cookieService: SiCookiesService,
    private getPointService: GetPointsService
  ) { }

  getPointGroupData(pointGroupPageCursor?: string) {

    const orgId = this.cookieService.getCookie('organizationId');
    let url = `${environment.pointApiUrlV3}/partitions/${orgId}/point-groups`;
    if (pointGroupPageCursor) {
      const uri = encodeURIComponent(pointGroupPageCursor);
      url = `${environment.pointApiUrlV3}/partitions/${orgId}/point-groups?page[after]=${uri}`;
    }
    return this.http.get(url).pipe(
      retry(5),
      catchError(error => {
        console.error('Fehler:', error);
        return throwError(error);
      })
    );
  }

  postPointGroup(name: string, type: string, id: string) {
    const orgId = this.cookieService.getCookie('organizationId');
    const url = `${environment.pointApiUrlV3}/partitions/${orgId}/point-groups`;
    const pg = new PostPointGroup(name, type, id);
    return this.http.post(url, pg).pipe(
      catchError(error => {
        if (error.status === 409) {
          pg.pgBasedOn();
          return this.http.post(url, pg);
        }
        return throwError(error);
      })
    );
  }

  patchPointGroup(pgId: string, newName: string) {
    const orgId = this.cookieService.getCookie('organizationId');
    const url = `${environment.pointApiUrlV3}/partitions/${orgId}/point-groups/${pgId}`;
    const payload = {
      data: {
        type: 'PointGroup',
        attributes: {
          name: newName
        }
      }
    };
    return this.http.patch(url, payload).pipe(
      catchError(error => {
        console.error('Error while patching point group:', error);
        return throwError(error);
      })
    );
  }

  deletePointGroup(pgId: string) {
    const orgId = this.cookieService.getCookie('organizationId');
    const url = `${environment.pointApiUrlV3}/partitions/${orgId}/point-groups/${pgId}`;
    return this.http.delete(url).pipe(
      catchError(error => {
        console.error('Error while deleting point group:', error);
        return throwError(error);
      })
    );
  }

  getPointGroups() {
    if (!this.subscriptionCheck) {
      this.loading = true;
      this.activeRequests++;
      this.getPointGroupData().subscribe(
        (res: any) => {
          this.getPointGroupPagination(res);
        },
        error => {
          this.decrementActiveRequests();
        },
        () => {
          this.decrementActiveRequests();
        }
      );
      this.subscriptionCheck = true;
    }
  }

  getPointGroupPagination(res: any) {
    res.data.forEach((element: PointGroupResult) => {
      this.activeRequests++;
      this.getPointService.getPointsFirstPage(element.id).subscribe((result: any) => {
        if (result.data.length > 0 || result.meta?.page?.cursor) {
          this.getPointService.pointGroupsTable.set(element.id, {
            pointGroupId: element.id,
            pointGroupName: element.attributes.name,
            pointCounts: 0,
            pointIds: [],
            loading: false,
            devices: false
          });//aenderung devices
          this.pointGroupsObserver.next(this.getPointService.pointGroupsTable);
        }
      },
      error => {
        this.decrementActiveRequests();
      },
      () => {
        this.decrementActiveRequests();
      });
    });

    const pointGroupPageCursor = res.meta?.page?.cursor;
    if (pointGroupPageCursor) {
      this.activeRequests++;
      this.getPointGroupData(pointGroupPageCursor).subscribe(
        (result: any) => this.getPointGroupPagination(result),
        error => {
          this.decrementActiveRequests();
        },
        () => {
          this.decrementActiveRequests();
        }
      );
    } else {
      this.decrementActiveRequests();
    }
  }

  private decrementActiveRequests() {
    this.activeRequests--;
    if (this.activeRequests === 0) {
      this.loading = false;
    }
  }
}

<h2 class="text-secondary ms-5 mt-5">Point Groups Modify</h2>
<div class="d-flex flex-column flex-xl-row ms-5 align-items-start">
  <!-- Create New Point Group -->
  <form class="me-3 mt-4 d-flex flex-column flex1">
    <h3 class="text-secondary mb-4">Create New Point Group</h3>
    <div class="mb-4">
      <input
        type="text"
        class="form-control formswidth"
        id="PGName"
        placeholder="Point Group Name"
        [(ngModel)]="pointGroupName"
        name="pointGroupName"
      />
      <small class="form-text">No empty Name</small>
    </div>

    <div class="border p-3 rounded mb-4">
      <h4 class="text-secondary mt-2">Entity</h4>
      <div class="mb-4">
        <input
          type="text"
          class="form-control formswidth"
          id="PGType"
          placeholder="Entity Type"
          [(ngModel)]="pointGroupType"
          name="pointGroupType"
        />
        <small class="form-text">Specify the type</small>
      </div>
      <div>
        <input
          type="text"
          class="form-control formswidth"
          id="PGId"
          placeholder="Entity ID"
          [(ngModel)]="pointGroupId"
          name="pointGroupId"
        />
        <small class="form-text">Specify the ID</small>
      </div>
    </div>
    <div class="text-end">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="createPointGroup()"
        [disabled]="!pointGroupName.trim() || !pointGroupType.trim() || !pointGroupId.trim()">
        Create
      </button>
    </div>
  </form>

  <!-- Update and Delete Point Group -->
  <div class="mt-4 flex2">
    <h3 class="text-secondary mb-4">Update and Delete Point Group</h3>

    <div class="d-flex flex-column flex-md-column flex-xl-row">
      <div class="mb-4 me-md-0 col-md-12 col-xl-5 me-xl-4">
        <si-search-bar
          placeholder="Search Point Group ID"
          aria-label="Search"
          [siTypeahead]="typeaheadData"
          [typeaheadSkipSortingMatches]="false"
          [typeaheadTokenize]="false"
          [disabled]="false"
          [showIcon]="true"
          [typeaheadScrollable]="true"
          [(ngModel)]="filter"
          (typeaheadOnSelect)="onTypeaheadSelect($event)"
        ></si-search-bar>
      </div>
      <div class="mb-4 col-md-12 col-xl-6">
        <input
          type="text"
          class="form-control formswidth pe-3"
          id="PGNameChange"
          placeholder="New Name"
          [(ngModel)]="newPointGroupName"
          name="pointGroupName"
        />
        <small class="form-text">New Name</small>
      </div>
    </div>

    <div class="d-flex justify-content-start flex-column flex-md-row">
      <button
        type="button"
        class="btn btn-secondary me-2"
        (click)="savePointGroup()">
        Save
      </button>
      <button
        type="button"
        class="btn btn-danger mt-2 mt-md-0 ms-md-2 me-2"
        (click)="deletePointGroup()">
        Delete
      </button>
    </div>
  </div>
</div>

import { DateRange } from '@simpl/element-ng';

export class Point {

  type!: string;
  id!: string;
  attributes!: {
    createdAt: string;
    dataType: string;
    description: string;
    deviceId: string;
    isActive: boolean;
    isDeleted: boolean;
    lastUpdatedAt: string;
    name: string;
    parentId: string;
    source: {
      namespace: string;
      localDeviceRef: string;
      type: string;
      localPointRef: string;
      namespaceType: string;
    };
    systemAttributes: Record<string, unknown>;//{}
    tags: Record<string, unknown>;//{}
    valueType: string;
    unit: string;
    enum: object;
    minimum: number;
    maximum: number;
    precision: number;
    commandingSemantic: string;
    function: string;
    lastValue?: {
      value?: string;
      createdAt?: Date;
      qualityOfValue?: number;
      targetValue?: string;
      attributes?: object;
    };
  };
}
export class PointMeta {
  page!: {
    cursor: string;
  };
}

export class GetPointResult {
  data!: Point[];
  meta!: PointMeta;
}

export class PointGroup {
  devices!: boolean;
  [key: string]: any;
  pointGroupId!: string;
  pointGroupName!: string;
  pointCounts!: number;
  pointIds!: string[];
  loading!: boolean;
}

export class PointGroupResult {
  type!: string;
  id!: string;
  attributes!: {
    entity: {
      type: string;
      id: string;
    };
    isRoot: boolean;
    name: string;
  };
  meta?: {
    createdAt: string;
    lastUpdatedAt: string;
  };
}

export class COVs {
  paginationCursor!: string;
  covs!: {
    createdAt: DateRange;
    attributes: object; //{}
    value: {
      additionalProp1: object; //{}
    };
    targetValue: string;
    qualityOfValue: number;
  }[];
}

export class COVsValues {
  createdAt!: string;
  targetValue: any;
  qualityOfValue: any;
}

export class PointValue {
  type!: string;
  id!: string;
  attributes!: {
    lastValue: {
      value: string;
      createdAt: Date;
      qualityOfValue: number;
      targetValue: string;
      attributes: object;
    };
  };
}

export class PointValues {
  data!: [
    {
      type: string;
      id: string;
      attributes: {
        lastValue: {
          value: string;
          createdAt: Date;
          qualityOfValue: number;
          targetValue: string;
          attributes: object;
        };
      };
    }
  ];
  meta!: {
    page: {
      cursor: string;
    };
  };
}

export class MyMap {
  id!: string;
  [key: string]: any;
  name!: string;
  datatype!: string;
  description!: string;
  value!: string;
  namespace!: string;
}

export class PostPointGroup {
  data!: {
    type?: string;
    id?: string;
    attributes: {
      name: string;
      entity?: {
        id: string;
        type: string
      };
      configuration?: {
        resolution: number;
        filters: {
          deviation: number;
          interval: number
        }
      };
      isRoot?: boolean
    };
    relationships?: {
      controlledBy?: {
        data: [
          {
            type: string;
            id: string
          }
        ]
      };
      fedBy?: {
        data: [
          {
            type: string;
            id: string
          }
        ]
      };
      partOf?: {
        data: [
          {
            type: string;
            id: string
          }
        ]
      };
      usesGateway?: {
        data: [
          {
            type: string;
            id: string
          }
        ]
      };
      usesEquipment?: {
        data: [
          {
            type: string;
            id: string
          }
        ]
      };
      basedOn?: {
        data: [
          {
            type: string;
            id: string
          }
        ]
      }
    };
    meta?: {
      createdAt: string;
      lastUpdatedAt: string
    }
  }

  constructor(
    name: string,
    type: string,
    id: string
  ) {
    this.data = { attributes: { name: name, entity: { type: type, id: id } }, type: 'PointGroup' }
  }

  pgBasedOn() {
    if (this.data.attributes && this.data.attributes.entity) {
      this.data.relationships = {
        basedOn: { data: [{ type: 'PointGroup', id: `${this.data.attributes.entity.type}-${this.data.attributes.entity.id}` }] }
      };
    }
  }
}

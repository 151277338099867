import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TableColumn } from '@siemens/ngx-datatable';
import { Filter } from '@simpl/element-ng';
import { SI_DATATABLE_CONFIG } from '@simpl/element-ng/datatable';

import { Subscription } from 'rxjs';
import { PointGroup } from 'src/app/model/point.models';

import { GetPointGroupsService } from 'src/app/services/get-point-groups.service';
import { GetPointsService } from 'src/app/services/get-points.service';
import { parseFiltersFromParams } from '../main.filter';

const pgFilterType = 'pg';
@Component({
  standalone: false,
  selector: 'app-point-group',
  templateUrl: './point-group.component.html',
  styleUrls: ['./point-group.component.scss']
})

export class PointGroupComponent implements OnInit {
  @ViewChild('copyIdPointButton', { static: true }) copyIdPointButton!: TemplateRef<any>;
  @ViewChild('copyPointGroupId', { static: true }) copyPointGroupId!: TemplateRef<any>;
  @ViewChild('copyPointGroupName', { static: true }) copyPointGroupName!: TemplateRef<any>;
  @ViewChild('loadingTemp', { static: true }) loadingTemp!: TemplateRef<any>;
  @ViewChild('countTempl', { static: true }) countTempl!: TemplateRef<any>;
  @ViewChild('devicesTempl', { static: true }) devicesTempl!: TemplateRef<any>;

  p = 1;
  rows: any[] = [];
  pointGroupMyMap: PointGroup[] = [];
  pointGroupIds: string[] = [];
  pointGroupNames: string[] = [];
  typeaheadData: string[] = [];
  columns!: TableColumn[];

  tableConfig = SI_DATATABLE_CONFIG;

  pointGroupServiceSubscription?: Subscription;
  pointServiceSubscription?: Subscription;

  pgId!: string;
  filter = '';
  open!: boolean;
  filters: Filter[] = [];
  filtercolumns = new Map([['pointGroupId', 'ID'], ['pointGroupName', 'Name']]);
  searchMode = 'pointGroupId';

  constructor(
    public getPointGroupService: GetPointGroupsService,
    public getPointsService: GetPointsService,
    public router: Router,
    private route: ActivatedRoute
  ) { }


  ngOnInit(): void {
    if (this.getPointsService.pointGroupsTable.size === 0) {
      this.getPointGroupService.loading = true;
      this.getPointGroupService.getPointGroups();
    } else {
      this.getPointsService.loading = false;
      this.getPointGroupService.loading = false;
    }

    this.fillLocalTable(this.getPointsService.pointGroupsTable);

    this.pointGroupServiceSubscription = this.getPointGroupService.pointGroupsObserver.subscribe(value => {
      this.fillLocalTable(value);
      this.doFilter(this.filters);
    });

    this.pointServiceSubscription = this.getPointsService.pointsObserver.subscribe(() => {
      this.fillLocalTable(this.getPointsService.pointGroupsTable);
      this.doFilter(this.filters);
    });

    if (this.route.queryParams) {
      this.route.queryParams.subscribe(params => {
        this.pgId = params.pgId;
        this.fillLocalTable(this.getPointsService.pointGroupsTable);
      });
    }

    this.route.queryParams.subscribe(params => {
      this.pgId = params.pgId;
      this.filters = parseFiltersFromParams(params, pgFilterType);
      this.fillLocalTable(this.getPointsService.pointGroupsTable);
      this.doFilter(this.filters);
    });

    this.initTableColumn();
    this.updateTypeaheadData();
  }

  fillLocalTable(value: Map<string, PointGroup>) {
    this.pointGroupIds = Array.from(value.keys());
    this.pointGroupNames = Array.from(value.values()).map(group => group.pointGroupName || '');
    if (this.pgId) {
      this.rows = [[this.pgId, value.get(this.pgId)]];
    } else {
      this.rows = Array.from(value).sort((a, b) => a[0].localeCompare(b[0]));
    }

    this.pointGroupMyMap = this.rows.map(row => {
      let deviceType: 'device' | 'equipment' | 'default' = 'default';
      let devices = false;
      if (row[0].startsWith('Device-')) {
        deviceType = 'device';
        devices = true;
      } else if (row[0].startsWith('Equipment-')) {
        deviceType = 'equipment';
        devices = true;
      }
      return {
        deviceType,
        devices,
        pointGroupId: row[0],
        pointGroupName: row[1]?.pointGroupName,
        pointCounts: row[1]?.pointIds?.length,
        pointIds: [],
        loading: row[1]?.loading
      };
    });
    this.updateTypeaheadData();
  }

  getPointData(pointGroupId: string) {
    this.getPointsService.loading = true;
    this.getPointsService.getPointFromPointGroup(pointGroupId);
  }

  onTypeaheadSelect(event: any) {
    const selectedValue = event.option;
    if (selectedValue) {
      const newFilter: Filter = {
        status: 'default',
        filterName: selectedValue,
        title: this.filtercolumns.get(this.searchMode),
        description: selectedValue
      };
      this.filters.push(newFilter);
      this.doFilter(this.filters);
      this.filter = '';
    }
  }

  doFilter(filters: Filter[]) {
    this.fillLocalTable(this.getPointsService.pointGroupsTable);
    this.filters = filters;
    filters.forEach(filter => {
      this.pointGroupMyMap = this.pointGroupMyMap.filter(group => {
        const filterValue = filter.filterName.toLowerCase();
        if (group[this.findKey(filter.title)] == undefined) { return true; }
        return group[this.findKey(filter.title)].toLowerCase().includes(filterValue)
      });
    });
    this.updateQueryParams();
    this.updateTypeaheadData();
  }

  findKey(value?: string): string {
    let result = '';
    this.filtercolumns.forEach((label, key) => {
      if (label === value) {
        result = key;
      }
    });
    return result;
  }

  updateQueryParams() {
    const params: any = {
      pgId: this.pgId
    };
    this.filters.forEach((filter, index) => {
      params[`filter${pgFilterType}${index}`] = filter.filterName;
      params[`filter${pgFilterType}Title${index}`] = filter.title;
    });

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params
    });
  }

  adjustPage(page: number) {
    this.p = page;
  }

  numPages() {
    return Math.ceil(this.pointGroupMyMap.length / 10);
  }

  private initTableColumn(): void {
    this.columns = [
      { prop: 'devices',
        name: 'Type',
        width: 100,
        sortable: false,
        resizeable: false,
        canAutoResize: false,
        cellTemplate: this.devicesTempl
      },
      {
        prop: 'pointGroupId',
        name: 'PointGroup Id',
        width: 100,
        sortable: false,
        resizeable: true,
        canAutoResize: true,
        cellTemplate: this.copyPointGroupId
      },
      {
        prop: 'pointGroupName',
        name: 'PointGroup Name',
        width: 150,
        sortable: false,
        resizeable: true,
        canAutoResize: true,
        cellTemplate: this.copyPointGroupName
      },
      {
        prop: 'pointCounts',
        name: 'Count',
        width: 100,
        sortable: false,
        resizeable: true,
        canAutoResize: true,
        cellTemplate: this.countTempl

      },
      {
        prop: 'pointButton',
        name: 'Point',
        sortable: false,
        resizeable: true,
        canAutoResize: true,
        cellTemplate: this.copyIdPointButton
      },
      {
        prop: 'loadingFinished',
        name: 'Loading',
        sortable: false,
        resizeable: false,
        canAutoResize: true,
        cellTemplate: this.loadingTemp
      }
    ];
  }

  private updateTypeaheadData() {
    const suggestions: string[] = [];
    if (this.filtercolumns.has(this.searchMode)) {
      this.pointGroupMyMap.forEach(group => {
        const value = group[this.searchMode as keyof PointGroup];
        if (value) {
          suggestions.push(String(value));
        }
      });
    }
    this.typeaheadData = Array.from(new Set(suggestions));
  }
}

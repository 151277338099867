import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Filter } from '@simpl/element-ng';
import { MyMap, PointGroup } from 'src/app/model/point.models';

@Component({
  standalone: false,
  selector: 'app-app-table-filter',
  templateUrl: './app-table-filter.component.html',
  styleUrl: './app-table-filter.component.scss'
})
export class AppTableFilterComponent implements OnInit {
  @Input() filters: Filter[] = [];
  @Input() typeaheadData: any[] = [];
  @Input() searchMode = 'Filter';
  @Input() columns = new Map<string, string>;
  @Input() filter = '';
  @Input() dataMap: any[] = [];
  @Input() objectType!: 'PointGroup' | 'MyMap';

  @Output() filterChange = new EventEmitter<any>();
  @Output() typeaheadSelect = new EventEmitter<any>();
  @Output() updateQueryParams = new EventEmitter<void>();
  @Output() doFilter = new EventEmitter<Filter[]>();

  open = false;

  ngOnInit(): void {
    this.filters = [];
  }

  setFilter(action: string) {
    this.searchMode = action;
    this.updateTypeaheadData();
    this.open = false;
  }

  onSearch(event: any) {
    if (event.key === 'Enter' && this.filter && !event.__zone_symbol__propagationStopped) {
      const newFilter: Filter = {
        status: 'default',
        filterName: this.filter,
        title: this.columns.get(this.searchMode),
        description: this.filter
      };
      this.filters.push(newFilter);
      this.doFilter.emit(this.filters);
      this.filter = '';
    }
  }

  onTypeaheadSelect(event: any) {
    const selectedValue = event.option;
    if (selectedValue) {
      const newFilter: Filter = {
        status: 'default',
        filterName: selectedValue,
        title: this.columns.get(this.searchMode),
        description: selectedValue
      };
      this.filters.push(newFilter);
      this.doFilter.emit(this.filters);
      this.filter = '';
    }
  }

  removeFilter(filters: Filter[]) {
    this.filters = filters;
    this.doFilter.emit(this.filters);

    this.updateQueryParams.emit();
  }

  dropdownItems(columns: Map<string, string>) {
    const items: any[] = [];
    columns.forEach((value, key) => {
      items.push({ label: value, value: key });
    });
    return items;
  }

  updateTypeaheadData() {
    const suggestions: string[] = [];
    if (this.columns.has(this.searchMode)) {
      this.dataMap.forEach(item => {
        const value = this.getDynamicValue(item, this.searchMode);
        if (value) {
          suggestions.push(String(value));
        }
      });
    }
    this.typeaheadData = Array.from(new Set(suggestions));
  }

  private getDynamicValue(item: any, key: string) {
    switch (this.objectType) {
      case 'PointGroup':
        return item[key as keyof PointGroup];
      case 'MyMap':
        return item[key as keyof MyMap];
      default:
        return null;
    }
  }
}
